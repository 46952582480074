<template>
    <div>
        <h1> Test Echo Page</h1>
        <br/>
        <div style="border: 1px solid skyblue; padding: 15px">
            <pre>{{ online }}</pre>
        </div>
        <hr>
        <div class="bg-light p-2 d-flex">
            <textarea v-model="greeting" rows="4"></textarea>
            <el-button class="ml-4" type="success" @click="sendGreeting">send</el-button>
        </div>
        <hr>
        <el-button :type="pusher?'danger':'primary'" @click="connect">{{ pusher ? 'Disconnect' : 'Connect' }} Pusher</el-button>
    </div>
</template>

<script>
import PusherMixin from "@/common/mixins/live-echo-mixin";
import CommonMixin from "@/common/mixins/common-mixin";

export default {
    name: "test-echo",
    mixins: [PusherMixin,CommonMixin],
    data() {
        return {
            pusher: null,
            online: {},
            greeting:'greeting',
            app:{
                host:'127.0.0.1',
                port:'8804',
                path:'',
                key:'live2021',
            },
            channel:'syslog',
        }
    },
    methods: {
        connect(){
            const vm = this;
            if (!this.pusher) {
                 const pusher = this.initPusher();
                 pusher.subscribe(this.channel)
                .bind('Online', (e) => {
                        vm.online = e;
                        console.log('Online', e)
                });
            } else {
                this.disconnectPusher()
            }
        },
        sendGreeting() {
            if (this.pusher) {
                this.pusher.send_event('heartbeat', {
                    client: this.client_token,
                    user_token: this.user.token,
                    data:this.greeting,
                }, 'App\\Events\\Online');
            }
        }
    }
}
</script>

<style scoped>

</style>
